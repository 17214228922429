import * as React from "react"

const MultiformatIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
	<svg
		width="16"
		height="20"
		viewBox="0 0 16 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.1112 2.12988C14.2218 2.08919 14.3387 2.06836 14.4566 2.06836H14.4648C15.0171 2.06836 15.4648 2.51607 15.4648 3.06836V11.8473C15.4648 15.8551 12.2158 19.1041 8.20794 19.1041V17.1041C11.1112 17.1041 13.4648 14.7505 13.4648 11.8473V4.57396C11.6565 5.44666 10.0483 6.67044 8.72914 8.15746L7.23302 6.8302C9.08433 4.74334 11.4403 3.11269 14.1112 2.12988Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.535156 3.06836C0.535156 2.51607 0.982871 2.06836 1.53516 2.06836H1.54337C1.6612 2.06836 1.77811 2.08919 1.88869 2.12988C7.97677 4.37005 12.4368 9.98077 13.0024 16.7042C13.0307 17.0411 12.8869 17.3695 12.6201 17.5772C11.3908 18.5338 9.84385 19.1041 8.16653 19.1041H7.79199C3.78416 19.1041 0.535156 15.8551 0.535156 11.8473V3.06836ZM2.53516 4.57394V11.8473C2.53516 14.7505 4.88872 17.1041 7.79199 17.1041H8.16653C9.19033 17.1041 10.144 16.8123 10.9512 16.3069C10.3186 11.1264 7.05495 6.75509 2.53516 4.57394Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.55736 0.967379C7.79629 0.871551 8.06296 0.871551 8.30188 0.967379C9.0907 1.28376 9.79896 1.77222 10.375 2.39721C10.951 3.02221 11.3802 3.76799 11.6314 4.57993C11.7946 5.10756 11.4991 5.66757 10.9715 5.83076C10.4439 5.99395 9.88386 5.69851 9.72067 5.17088C9.55749 4.64327 9.27858 4.15869 8.90434 3.75265C8.6232 3.4476 8.29355 3.1926 7.92962 2.99745C7.5657 3.1926 7.23605 3.4476 6.9549 3.75265C6.58067 4.15869 6.30176 4.64327 6.13858 5.17088C5.97539 5.69851 5.41538 5.99395 4.88775 5.83076C4.36013 5.66757 4.06469 5.10756 4.22788 4.57993C4.479 3.76799 4.90822 3.02221 5.48426 2.39721C6.06028 1.77222 6.76854 1.28376 7.55736 0.967379Z"
			fill="#7D828C"
		/>
	</svg>
)

export default MultiformatIcon
