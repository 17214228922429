import * as React from "react"

const FoodtrackIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
	<svg
		width="21"
		height="19"
		viewBox="0 0 21 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.289795 1.89453C0.289795 1.34225 0.73751 0.894531 1.28979 0.894531H13.139C13.4042 0.894531 13.6586 0.999888 13.8461 1.18742C14.0336 1.37496 14.139 1.62931 14.139 1.89453V10.0133H12.139V2.89453H2.28979V10.0133H0.289795V1.89453Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.305 4.78761L13.1507 4.78765C12.5984 4.78766 12.1506 4.33995 12.1506 3.78767C12.1506 3.23538 12.5983 2.78766 13.1506 2.78765L15.9013 2.7876C16.2687 2.78759 16.6064 2.989 16.7811 3.31219L20.1467 9.54032C20.4092 10.0262 20.2282 10.6329 19.7423 10.8955C19.2564 11.1581 18.6497 10.977 18.3871 10.4911L15.305 4.78761Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.28979 9.01367C1.84208 9.01367 2.28979 9.46139 2.28979 10.0137V14.3771H3.53836C4.09064 14.3771 4.53836 14.8248 4.53836 15.3771C4.53836 15.9293 4.09064 16.3771 3.53836 16.3771H1.28979C0.73751 16.3771 0.289795 15.9293 0.289795 15.3771V10.0137C0.289795 9.46139 0.73751 9.01367 1.28979 9.01367ZM3.11991 10.0137C3.11991 9.46139 3.56763 9.01367 4.11991 9.01367H19.2783C19.8306 9.01367 20.2783 9.46139 20.2783 10.0137V15.3771C20.2783 15.9293 19.8306 16.3771 19.2783 16.3771H17.0297C16.4775 16.3771 16.0297 15.9293 16.0297 15.3771C16.0297 14.8248 16.4775 14.3771 17.0297 14.3771H18.2783V11.0137H4.11991C3.56763 11.0137 3.11991 10.566 3.11991 10.0137ZM7.98616 15.3771C7.98616 14.8248 8.43388 14.3771 8.98616 14.3771H11.5819C12.1342 14.3771 12.5819 14.8248 12.5819 15.3771C12.5819 15.9293 12.1342 16.3771 11.5819 16.3771H8.98616C8.43388 16.3771 7.98616 15.9293 7.98616 15.3771Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.18916 14.0103C5.61061 14.0103 5.1416 14.4793 5.1416 15.0578C5.1416 15.6364 5.61061 16.1054 6.18916 16.1054C6.76771 16.1054 7.23672 15.6364 7.23672 15.0578C7.23672 14.4793 6.76771 14.0103 6.18916 14.0103ZM3.1416 15.0578C3.1416 13.3747 4.50604 12.0103 6.18916 12.0103C7.87228 12.0103 9.23672 13.3747 9.23672 15.0578C9.23672 16.7409 7.87228 18.1054 6.18916 18.1054C4.50604 18.1054 3.1416 16.7409 3.1416 15.0578Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.3789 14.0103C13.8003 14.0103 13.3313 14.4793 13.3313 15.0578C13.3313 15.6364 13.8003 16.1054 14.3789 16.1054C14.9574 16.1054 15.4264 15.6364 15.4264 15.0578C15.4264 14.4793 14.9574 14.0103 14.3789 14.0103ZM11.3313 15.0578C11.3313 13.3747 12.6957 12.0103 14.3789 12.0103C16.062 12.0103 17.4264 13.3747 17.4264 15.0578C17.4264 16.7409 16.062 18.1054 14.3789 18.1054C12.6957 18.1054 11.3313 16.7409 11.3313 15.0578Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.23608 3.9375C5.78837 3.9375 6.23608 4.38522 6.23608 4.9375V4.99204C6.23608 5.53258 6.67427 5.97077 7.21481 5.97077C7.75534 5.97077 8.19353 5.53258 8.19353 4.99204V4.9375C8.19353 4.38522 8.64125 3.9375 9.19353 3.9375C9.74581 3.9375 10.1935 4.38522 10.1935 4.9375V4.99204C10.1935 6.63715 8.85991 7.97077 7.21481 7.97077C5.5697 7.97077 4.23608 6.63715 4.23608 4.99204V4.9375C4.23608 4.38522 4.6838 3.9375 5.23608 3.9375Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.19263 3.9375C9.74491 3.9375 10.1926 4.38522 10.1926 4.9375V4.99204C10.1926 5.53258 10.6308 5.97077 11.1714 5.97077C11.7119 5.97077 12.1501 5.53258 12.1501 4.99204V4.9375C12.1501 4.38522 12.5978 3.9375 13.1501 3.9375C13.7024 3.9375 14.1501 4.38522 14.1501 4.9375V4.99204C14.1501 6.63715 12.8165 7.97077 11.1714 7.97077C9.52625 7.97077 8.19263 6.63715 8.19263 4.99204V4.9375C8.19263 4.38522 8.64034 3.9375 9.19263 3.9375Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.27832 3.9375C1.83061 3.9375 2.27832 4.38522 2.27832 4.9375V4.99204C2.27832 5.53258 2.71651 5.97077 3.25704 5.97077C3.79758 5.97077 4.23577 5.53258 4.23577 4.99204V4.9375C4.23577 4.38522 4.68348 3.9375 5.23577 3.9375C5.78805 3.9375 6.23577 4.38522 6.23577 4.9375V4.99204C6.23577 6.63715 4.90215 7.97077 3.25704 7.97077C1.61194 7.97077 0.27832 6.63715 0.27832 4.99204V4.9375C0.27832 4.38522 0.726036 3.9375 1.27832 3.9375Z"
			fill="#7D828C"
		/>
	</svg>
)

export default FoodtrackIcon
