import * as React from "react"

const CafeIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 5.5C16 4.94772 16.4477 4.5 17 4.5H19.5C21.433 4.5 23 6.067 23 8C23 9.933 21.433 11.5 19.5 11.5H17C16.4477 11.5 16 11.0523 16 10.5V5.5ZM18 6.5V9.5H19.5C20.3284 9.5 21 8.82843 21 8C21 7.17157 20.3284 6.5 19.5 6.5H18Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 5.5C3 4.94772 3.44772 4.5 4 4.5H17C17.5523 4.5 18 4.94772 18 5.5V12C18 16.1421 14.6421 19.5 10.5 19.5C6.35786 19.5 3 16.1421 3 12V5.5ZM5 6.5V12C5 15.0376 7.46243 17.5 10.5 17.5C13.5376 17.5 16 15.0376 16 12V6.5H5Z"
			fill="#7D828C"
		/>
	</svg>
)

export default CafeIcon
