import * as React from "react"

const CoffeeIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.4335 2.54876C7.54987 2.11051 7.94656 1.80542 8.4 1.80542H15.6C16.0534 1.80542 16.4501 2.11051 16.5665 2.54876L17.2691 5.1946H19C19.5523 5.1946 20 5.64232 20 6.1946V10.1946C20 10.7469 19.5523 11.1946 19 11.1946H18.3783L17.06 21.3237C16.9951 21.8219 16.5707 22.1946 16.0684 22.1946H7.93165C7.42926 22.1946 7.00485 21.8219 6.94001 21.3237L5.62172 11.1946H5C4.44772 11.1946 4 10.7469 4 10.1946V6.1946C4 5.64232 4.44772 5.1946 5 5.1946H6.73089L7.4335 2.54876ZM8.80021 5.1946H15.1998L14.8309 3.80542H9.16911L8.80021 5.1946ZM18 9.1946V7.1946H6V9.1946H18ZM7.63858 11.1946L8.80993 20.1946H15.1901L16.3614 11.1946H7.63858Z"
			fill="#7D828C"
		/>
	</svg>
)

export default CoffeeIcon
