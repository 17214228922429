import * as React from "react"

const RestaurantIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.375 18.2188C1.375 17.6665 1.82272 17.2188 2.375 17.2188H21.6249C22.1772 17.2188 22.6249 17.6665 22.6249 18.2188C22.6249 18.771 22.1772 19.2188 21.6249 19.2188H2.375C1.82272 19.2188 1.375 18.771 1.375 18.2188Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 10.9688C10.0772 10.9688 8.23311 11.7326 6.87348 13.0922C5.51384 14.4519 4.75 16.2959 4.75 18.2187C4.75 18.771 4.30228 19.2187 3.75 19.2187C3.19772 19.2187 2.75 18.771 2.75 18.2187C2.75 15.7655 3.72456 13.4127 5.45926 11.678C7.19397 9.9433 9.54673 8.96875 12 8.96875C14.4532 8.96875 16.806 9.9433 18.5407 11.678C20.2754 13.4127 21.2499 15.7655 21.2499 18.2187C21.2499 18.771 20.8022 19.2187 20.2499 19.2187C19.6977 19.2187 19.2499 18.771 19.2499 18.2187C19.2499 16.2959 18.4861 14.4519 17.1265 13.0922C15.7668 11.7326 13.9228 10.9688 12 10.9688Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 6.78125C11.4132 6.78125 10.9375 7.25695 10.9375 7.84375C10.9375 8.43056 11.4132 8.90626 12 8.90626C12.5868 8.90626 13.0625 8.43056 13.0625 7.84375C13.0625 7.25695 12.5868 6.78125 12 6.78125ZM8.9375 7.84375C8.9375 6.15238 10.3086 4.78125 12 4.78125C13.6914 4.78125 15.0625 6.15238 15.0625 7.84375C15.0625 9.53513 13.6914 10.9063 12 10.9063C10.3086 10.9063 8.9375 9.53513 8.9375 7.84375Z"
			fill="#7D828C"
		/>
	</svg>
)

export default RestaurantIcon
