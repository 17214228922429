import * as React from "react"

const BarIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 2.5C4.72459 2.5 4.46137 2.61359 4.27243 2.81397C4.08349 3.01435 3.98555 3.28379 4.00173 3.55872L5.00173 20.5587C5.03282 21.0873 5.47053 21.5 6 21.5H18C18.5295 21.5 18.9672 21.0873 18.9983 20.5587L19.9983 3.55872C20.0145 3.28379 19.9165 3.01435 19.7276 2.81397C19.5386 2.61359 19.2754 2.5 19 2.5H5ZM6.38461 10.009L6.06055 4.5H17.9394L17.7329 8.01075C14.6764 8.01325 13.1046 8.54867 11.5999 9.06485L11.5831 9.07063C10.2326 9.53392 8.93885 9.97776 6.38461 10.009ZM6.50217 12.0075L6.94291 19.5H17.0571L17.6153 10.0111C14.9604 10.0264 13.6426 10.4785 12.2657 10.9509L12.2489 10.9566C10.8089 11.4506 9.3075 11.9622 6.50217 12.0075Z"
			fill="#7D828C"
		/>
	</svg>
)

export default BarIcon
